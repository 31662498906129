<template>
  <div class="header-three">
    <v-navigation-drawer
      class="hidden-md-and-up"
      v-model="drawer"
      absolute
      temporary
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img src="../../assets/images/logo/logo.png" alt="brand-image" />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-for="item in coursesSidebarDropDownItems"
          :key="item.title"
          v-model="item.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            :ripple="false"
            v-for="child in item.items"
            :to="child.to"
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- End sidebar services dropdown item list -->

        <!-- End mobile menu sidebar item list -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      elevation="0"
      color="transparent"
      absolute
      class="default-header"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
      </router-link>
      <!-- End brand logo -->

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--35">
        <v-btn :ripple="false" text to="/">Home</v-btn>
        <v-menu
          open-on-hover
          bottom
          min-width="240"
          offset-y
          transition="scroll-y-reverse-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" text v-bind="attrs" v-on="on">
              Courses
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              v-for="(item, index) in CoursesdropDownItems"
              :key="index"
              :to="item.to"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- End Services dropdown -->
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        :ripple="false"
        class="ma-0 pa-0 hidden-md-and-up menu_icon"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data: () => ({
    drawer: false,
    items: [{ title: "Home", to: "/" }],
    CoursesdropDownItems: [
      { title: "LDTE 5000 - Theoretical Foundations in LDT", to: "/LDTE5000" },
      { title: "LDTE 5020 - Technology and the Mind", to: "/LDTE5020" },
      {
        title: "LDTE 5870 - Technology and Learning Innovations",
        to: "/LDTE5870",
      },
            {
        title: "LDTE 5870 - Instructional Design Application",
        to: "/LDTE5870-2",
      },
      {
        title: "LDTE 5870 - Issues, Practices, and Research",
        to: "/LDTE5870-3",
      },
      {
        title: "LDTE 5210 - Learning Experience Design",
        to: "/LDTE5210",
      },
    ],
    coursesSidebarDropDownItems: [
      {
        items: [
          {
            title: "LDTE 5000 - Theoretical Foundations in LDT",
            to: "/LDTE5000",
          },
          { title: "LDTE 5020 - Technology and the Mind", to: "/LDTE5020" },
          {
            title: "LDTE 5870 - Technology and Learning Innovations",
            to: "/LDTE5870",
          },
          {
            title: "LDTE 5870 - Instructional Design Application",
            to: "/LDTE5870-2",
          },
          {
            title: "LDTE 5870 - Issues, Practices, and Research",
            to: "/LDTE5870-3",
          },
          {
            title: "LDTE 5210 - Learning Experience Design",
            to: "/LDTE5210",
          },
        ],
        title: "Courses",
      },
    ],
    icon: "menu",
    closeIcon: "x",
  }),
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
