<template>
  <!-- Start Footer Style Two  -->
  <div class="footer-style-2 ptb--30 pt-60 bg_color--6" data-black-overlay="6">
    <div class="wrapper plr--50 plr_sm--20">
      <v-row align="center">
        <!-- End .col -->
        <v-col lg="12" md="12" sm="12" cols="12">
          <div class="text-center inner text-md-right mt_md--20 mt_sm--20">
            <div class="text">
              <p>
                Copyright © {{ new Date().getFullYear() }}
                <a
                  target="_blank"
                  class="copyright_txt"
                  href="https://codysquadroni.com"
                  >Cody Squadroni</a
                >
                All Rights Reserved.
              </p>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo/logo-light.png"),
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "https://www.facebook.com/",
        },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/",
        },
        {
          icon: "fa-twitter",
          url: "https://twitter.com/",
        },
        {
          icon: "fab fa-instagram",
          url: "https://www.instagram.com/",
        },
      ],
    };
  },
};
</script>
